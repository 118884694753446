var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.confirmLoading } },
    [
      _c(
        "j-form-container",
        { attrs: { disabled: _vm.formDisabled } },
        [
          _c(
            "a-form",
            { attrs: { slot: "detail", form: _vm.form }, slot: "detail" },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: "租户名称",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                          },
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["name"],
                                expression: "['name']",
                              },
                            ],
                            attrs: { placeholder: "请输入租户名称" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: "租户编号",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                          },
                        },
                        [
                          _c("a-input-number", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "id",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入租户编号",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "['id',{rules: [{ required: true, message: '请输入租户编号'}]}]",
                              },
                            ],
                            staticStyle: { width: "100%" },
                            attrs: { min: 1, placeholder: "请输入租户编号" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: "开始时间",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                          },
                        },
                        [
                          _c("j-date", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["beginDate"],
                                expression: "['beginDate']",
                              },
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择开始时间",
                              "trigger-change": true,
                              "show-time": true,
                              "date-format": "YYYY-MM-DD HH:mm:ss",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: "结束时间",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                          },
                        },
                        [
                          _c("j-date", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["endDate"],
                                expression: "['endDate']",
                              },
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择结束时间",
                              "trigger-change": true,
                              "show-time": true,
                              "date-format": "YYYY-MM-DD HH:mm:ss",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: "状态",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                          },
                        },
                        [
                          _c(
                            "a-radio-group",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: ["status", { initialValue: 1 }],
                                  expression: "[ 'status', {initialValue:1}]",
                                },
                              ],
                              attrs: { name: "tenantStatus" },
                            },
                            [
                              _c("a-radio", { attrs: { value: 1 } }, [
                                _vm._v("正常"),
                              ]),
                              _c("a-radio", { attrs: { value: 0 } }, [
                                _vm._v("冻结"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.showFlowSubmitButton
                    ? _c(
                        "a-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { span: 24 },
                        },
                        [
                          _c("a-button", { on: { click: _vm.submitForm } }, [
                            _vm._v("提 交"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }